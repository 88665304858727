import styled from 'styled-components'
import { breakpoints, BigHeader } from 'src/utils/styles/global-styles'
import { Link } from 'gatsby'
import { lighten } from 'polished'

export const ContentContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2rem auto 4rem;
  padding-top: 30px;
  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 0px;
  }
`
export const BlogPostsContainer = styled.section`
  b,
  strong {
    font-family: 'filsonProBold';
  }
  em {
    font-style: normal;
    font-family: 'P22MackinacProBook' !important;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.13px;
    color: ${(props) => props.theme.grayDark};
    margin-bottom: 10px;
    @media (max-width: ${breakpoints.s}px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
  div:nth-of-type(even) {
    @media (max-width: ${breakpoints.l}px) {
      flex-direction: column;
    }
  }
  div:nth-of-type(odd) {
    @media (max-width: ${breakpoints.l}px) {
      flex-direction: column-reverse;
    }
  }
  div > div,
  div > section {
    @media (max-width: ${breakpoints.l}px) {
      max-width: 80%;
    }
    @media (max-width: ${breakpoints.s}px) {
      max-width: 90%;
    }
  }
  div > div {
    width: 50%;
    @media (max-width: ${breakpoints.xl}px) {
      width: 100%;
    }
    @media (max-width: ${breakpoints.m}px) {
      width: 80%;
    }
    @media (max-width: ${breakpoints.s}px) {
      width: 100%;
    }
  }
  padding-top: 30px;
  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 0px;
  }
`
export const BlogContainer = styled(ContentContainer)`
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 1.2rem 5vw;
  align-items: stretch;
  @media (max-width: ${breakpoints.xl}px) {
    width: 90%;
  }
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    justify-content: center;
  }
`
export const Image = styled.img`
  width: 100%;
`
export const BlogImage = styled.img`
  width: 100%;
  border-radius: 36px;
  @media (max-width: ${breakpoints.s}px) {
    border-radius: 0px;
  }
`
export const BlogImageContainer = styled.div``
export const Text = styled.section`
  text-align: left;
  padding-left: 5rem;
  padding-right: 5rem;
  max-width: ${(props) => props.width || '50%'};
  margin: ${(props) => props.margin || '0px'};
  @media (max-width: ${breakpoints.xl}px) {
    padding: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding: 40px 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 20px 0px;
  }
`

export const Content = styled.div`
  font-family: 'FilsonPro';
  margin: 40px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: ${(props) => props.theme.blueDark};
    text-decoration: none;
    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      color: ${(props) => lighten(0.1, props.theme.blueDark)};
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px 0px;
    flex-wrap: wrap;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 0px 0px;
  }
  p,
  span {
    font-family: 'FilsonProBook';
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
    margin: 10px 0px;
    @media (max-width: 1100px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: ${breakpoints.m}px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: ${breakpoints.s}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`
export const ImageContainer = styled.div`
  width: 720px;
  > img {
    border-top-left-radius: 56px;
    border-bottom-left-radius: 56px;
    @media (max-width: ${breakpoints.l}px) {
      border-top-right-radius: 56px;
      border-bottom-right-radius: 56px;
    }
    @media (max-width: ${breakpoints.l}px) {
      width: 90%;
      margin-left: 5%;
    }
    @media (max-width: ${breakpoints.s}px) {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      width: 95%;
      margin-left: 5%;
    }
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5vw;
  @media (max-width: ${breakpoints.l}px) {
    padding-left: 0px;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`
export const Header = styled(BigHeader)`
  width: 400px;
  margin: 50px 0px;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 10px auto;
    width: 90%;
    text-align: center;
  }
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 28px;
  }
`
export const BlogHomeLink = styled(Link)`
  font-family: 'FilsonProBook';
  font-size: 15px;
  line-height: 26px;
  letter-spacing: 0.09px;
  color: ${(props) => props.theme.grayDark};
  text-decoration: none;
  text-align: left;
  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
    color: ${(props) => lighten(0.1, props.theme.grayDark)};
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 13px;
  }
`
export const ButtonContainer = styled.div`
  width: 80%;
  margin: 50px auto;
  padding-left: 5rem;
  padding-right: 5rem;
  @media (max-width: ${breakpoints.xl}px) {
    padding: 40px 40px 40px;
    margin: 40px auto;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
    margin: 0px auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 90%;
    padding: 20px 0px 30px;
  }
`
export const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
  h1,
  h2,
  h3 {
    font-family: 'P22MackinacProBold';
  }
`
export const BlogsImageContainer = styled.div`
  margin: 0px;
  height: 250px;
  > img {
    object-fit: cover;
  }
`
export const Card = styled.div`
  max-width: 500px;
  margin: 20px 50px 0px;
  border: 1px solid #e8e5d2;
  border-radius: 48px;
  overflow: hidden;
  background-color: white;
  height: 100%;
  @media (max-width: ${breakpoints.l}px) {
    width: 80%;
    margin: 0px auto 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px auto 20px;
  }
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  p {
    font-family: 'FilsonProBook';
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: ${(props) => props.theme.grayDark};
    border-bottom: 1.5px solid red;
    display: inline-block;
    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      color: ${(props) => lighten(0.1, props.theme.grayDark)};
    }
  }
`
export const CenteredHeader = styled(Header)`
  font-weight: normal;
  width: 100%;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 30px;
  padding-top: 40px;
  @media (max-width: ${breakpoints.xl}px) {
    padding-top: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 20px;
  }
`
export const SecondaryHeader = styled.h2`
  font-family: 'P22MackinacProBook';
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.19px;
  color: ${(props) => props.theme.golden};
  padding: 40px 40px 0px 40px;
  margin: 0px;
  text-align: center;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 30px;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 23px;
    line-height: 32px;
    letter-spacing: 0.15px;
    text-align: center;
    padding: 20px 10% 0px;
  }
`
export const CenterLink = styled.div`
  margin-top: 0px;
  text-align: center;
  padding-bottom: 20px;
  p {
    @media (max-width: ${breakpoints.s}px) {
      font-size: 16px;
    }
  }
`
export const LinkContainer = styled.div`
  display: inline;
  max-width: 90%;
  @media (max-width: ${breakpoints.l}px) {
    width: 800px;
  }
`
export const ContentDiv = styled(Content)`
  min-width: 100%;
`
export const BlogLink = styled.a`
  text-decoration: none;
  margin-bottom: 50px;
`
